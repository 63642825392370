<template>
  <div>
    <span class="d-block">
      <p class="mb-3">
        Authorization to submit application to processor and for processor to perform credit and background checks
      </p>

      <p class="mb-3">
        By submitting this application, you agree to our
        <a :href="tosUrl" target="_blank">
          Terms and Conditions
        </a>
        and to the credit underwriting process. We want to ensure a smooth application for your merchant account.
        Our payment processing partner(s) will review the information provided to assess creditworthiness.
        This process involves evaluating your financial history, business performance, and other relevant factors.
        All information provided is handled in accordance with our Privacy Policy. Usually, this process
        takes approximately 1-2 business days.
      </p>

      <p class="mt-4">
        Upload Documents:
      </p>

      <p class="mb-0">
        Once the information you provide is submitted to the processor for review, you will be sent a link by
        email to securely upload necessary documents such as identification, voided check or bank letter,
        minimum of three months of merchant processing statements and business bank account statements.
      </p>
    </span>
    <span class="d-block mt-2 mb-2">
      I accept the
      <a :href="tosUrl" target="_blank">
        Terms and Conditions
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'MerchantServiceAgreement',
  props: {
    tosUrl: {
      type: String,
      default: '',
    },
  },
}
</script>
