var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", { staticClass: "d-block" }, [
      _c("p", { staticClass: "mb-3" }, [
        _vm._v(
          "\n      Authorization to submit application to processor and for processor to perform credit and background checks\n    "
        ),
      ]),
      _c("p", { staticClass: "mb-3" }, [
        _vm._v(
          "\n      By submitting this application, you agree to our\n      "
        ),
        _c("a", { attrs: { href: _vm.tosUrl, target: "_blank" } }, [
          _vm._v("\n        Terms and Conditions\n      "),
        ]),
        _vm._v(
          "\n      and to the credit underwriting process. We want to ensure a smooth application for your merchant account.\n      Our payment processing partner(s) will review the information provided to assess creditworthiness.\n      This process involves evaluating your financial history, business performance, and other relevant factors.\n      All information provided is handled in accordance with our Privacy Policy. Usually, this process\n      takes approximately 1-2 business days.\n    "
        ),
      ]),
      _c("p", { staticClass: "mt-4" }, [
        _vm._v("\n      Upload Documents:\n    "),
      ]),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "\n      Once the information you provide is submitted to the processor for review, you will be sent a link by\n      email to securely upload necessary documents such as identification, voided check or bank letter,\n      minimum of three months of merchant processing statements and business bank account statements.\n    "
        ),
      ]),
    ]),
    _c("span", { staticClass: "d-block mt-2 mb-2" }, [
      _vm._v("\n    I accept the\n    "),
      _c("a", { attrs: { href: _vm.tosUrl, target: "_blank" } }, [
        _vm._v("\n      Terms and Conditions\n    "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }